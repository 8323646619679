import { SpeakerWaveIcon } from '@heroicons/react/24/outline'
import React from 'react'

export default function FilePreview({ asset, width = 400, height = 'auto', name = "fileDe" }) {
  const type = asset?.[name]?.generalType
  const isGif = asset?.[name]?.mimeType?.includes('gif')

  return (
    <div className='relative'>
      {!asset?.[name] && (
        <div className="p-4">No Preview.</div>
      )}
      {type === 'image' && (
        <>
          <picture>
            <source
              type="image/webp"
              src={`https://assets.zuckerjagdwurst.com/${asset?.[name]?.key}/${width}/${height}/65/true/center/${asset?.[name]?.filename.replace(/\.[^/.]+$/, '')}.webp?animated=${isGif}`}
            />
            <img
              src={`https://assets.zuckerjagdwurst.com/${asset?.[name]?.key}/${width}/${height}/65/true/center/${asset?.[name]?.filename.replace(/\.[^/.]+$/, '')}${isGif ? '.webp' : '.jpg'}?animated=${isGif}`}
              loading="lazy"
              alt={asset?.titleDe}
            />
          </picture>
          {isGif && (
            <div className="absolute right-2 bottom-2 rounded-full px-1 py-0.5 text-xs bg-violet-500 text-bold text-violet-50">GIF</div>
          )}
        </>
      )}
      {type === 'audio' && (
        <div className="p-2 flex space-x-2 items-center">
          <SpeakerWaveIcon className="w-6 h-6" />
          <div className="truncate flex-1">{asset[name].filename}</div>
        </div>
      )}
    </div>
  )
}
