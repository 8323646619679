export default `
  id
  titleDe
  fileDe {
    id
    key
    generalType
    mimeType
    filename(withExtension: true)
  }
`
