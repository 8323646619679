
export default `
  id
  titleDe
  fileDe {
    id
    key
    generalType
    filename(withExtension: true)
    mimeType
  }
  updatedAt
  updater {
    id
    displayName
  }
`
