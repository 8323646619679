import coverImageFragment from './cover-image'
import metaTagsFragment from './meta-tags'
import sectionsFragment from './sections'
import timestampsFragment from './timestamps'

export default `
  id
  titleDe
  titleEn
  slugDe
  slugEn
  teaserDe
  teaserEn
  publishDate
  coverImage {
    ${coverImageFragment}
  }
  showCoverImage
  sections {
    ${sectionsFragment}
  }
  ${metaTagsFragment}
  ${timestampsFragment}
`
